import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa'; // Importer l'icône Google
import { auth, provider, db } from '../firebase'; // Importer l'authentification Firebase et Firestore
import { signInWithPopup } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore'; // Importer les fonctions pour Firestore
import './assets/css/Login.css'; // Importer le fichier CSS

const Login = () => {
  const navigate = useNavigate();

  // Vérifier si l'utilisateur est déjà connecté
  useEffect(() => {
    const checkUser = () => {
      const user = localStorage.getItem('user');
      if (user) {
        //navigate('/homes'); // Redirection si l'utilisateur est déjà connecté
      }
    };

    checkUser();
  }, [navigate]);

  // Fonction pour gérer la connexion avec Google
  const onGoogleButtonPress = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      const userInfo = {
        uid: user.uid,
        uname: user.displayName,
        email: user.email,
        image: user.photoURL,
        buyed: [], // Tableau vide pour les livres achetés
        biblio: [], // Tableau vide pour la bibliothèque de l'utilisateur
        favories: [], // Tableau vide pour les livres favoris
        telecharge: [], // Tableau vide pour les livres téléchargés
        isConnected: true, // Statut de connexion de l'utilisateur
      };
  
      // Enregistrer les informations utilisateur dans localStorage
      localStorage.setItem('user', JSON.stringify(userInfo));
  
      // Enregistrer l'utilisateur dans Firestore
      console.log("Enregistrement dans Firestore :", userInfo); // Log pour vérifier l'objet
      await setDoc(doc(db, 'users', user.uid), userInfo);
      console.log("Utilisateur enregistré avec succès dans Firestore.");
  
      // Redirection après la connexion
      navigate('/homes');
    } catch (error) {
      console.error("Erreur de connexion avec Google :", error);
      alert("Une erreur s'est produite lors de la connexion. Veuillez réessayer.");
    }
  };
  

  return (
    <div className="container-fluid">
      <div className="header">
        <img
          className="header-image"
          src={require('./assets/images/header.jpg')}
          alt="Header"
        />
        <div className="gradient-overlay"></div> {/* Couche de dégradé */}
        <div className="header-content">
         
          <div className="welcome-text">
            <h1 className="welcome-title">Bienvenue sur</h1>
            <h2 className="welcome-subtitle">Papers</h2>
            <div className="logo-container">
            <img
              className="logo"
              src={require('./assets/images/logo.png')}
              alt="Logo"
            />
          </div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <button className="google-button" onClick={onGoogleButtonPress}>
          <FaGoogle className="google-icon" />
          <span className="button-text">Continuer avec Google</span>
        </button>
      </div>
    </div>
  );
};

export default Login;
